<template>
  <div class="card p-shadow-6">
    <h1>Extranet Grupos de Utilizadores</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="groupsList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="name"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem utilizadores. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('extranetAddUser')"
          >
            <Button icon="pi pi-plus" @click="newGroup()" />
          </div>
        </template>
        <Column
          field="name"
          filterField="name"
          header="Nome"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '25%', 'max-width': '25%' }"
          :filterHeaderStyle="{ 'min-width': '25%', 'max-width': '25%' }"
          :bodyStyle="{ 'min-width': '25%', 'max-width': '25%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="emails"
          filterField="emails"
          header="Emails"
          sortable
          filterMatchMode="contains"
          :headerStyle="{ 'min-width': '70%', 'max-width': '70%' }"
          :filterHeaderStyle="{ 'min-width': '70%', 'max-width': '70%' }"
          :bodyStyle="{ 'min-width': '70%', 'max-width': '70%' }"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          :headerStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :filterHeaderStyle="{ 'min-width': '5%', 'max-width': '5%' }"
          :bodyStyle="{ 'min-width': '5%', 'max-width': '5%' }"
        >
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('UpdateExtranetGroups')"
              icon="pi pi-pencil"
              class="p-button-sm p-button-rounded p-button-success"
              @click="updateGroup(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="groupModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (groupModel.id == undefined ? 'Criar' : 'Actualizar') + ' Utilizador'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="usersGroupForm" class="p-col-12" @submit.prevent="saveGroup">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="groupModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('groupName') },
                  'form-control',
                ]"
                :id="'groupName'"
                :name="'groupName'"
              />
              <label for="contact_id">Nome</label>
            </span>
            <small v-if="errors.has('groupName')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-2">
            <span class="p-float-label">
              <Chips
                name="emails"
                :allowDuplicate="false"
                style="width: 100%"
                separator=","
                v-model="groupModel.emails"
              />
              <label for="emails">emails</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveGroup"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeGroupForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api/";
import extranetService from "../services/extranet.service";
export default {
  name: "ExtranetGroups",
  data() {
    return {
      loading: false,
      groupsList: [],
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        emails: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      groupModel: { show: false },
    };
  },
  async created() {
    this.loading = true;
    await this.getGroups();
    this.loading = false;
  },
  methods: {
    async getGroups() {
      this.loading = true;
      let groups = await extranetService.getGroups();
      this.groupsList = groups.map((v) => {
        v.emailList = v.group_emails.map((m) => m.user_email);
        v.emails = v.emailList.join(",");
        return v;
      });
      return (this.loading = false);
    },
    newGroup() {
      return (this.groupModel = {
        showForm: true,
      });
    },
    closeGroupForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.groupModel = {
        showForm: false,
      });
    },
    saveGroup() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.groupModel.id == undefined) {
          return this.saveNewGroup();
        }
        return this.saveUpdateGroup();
      });
    },
    saveNewGroup() {
      let bodyParams = {
        name: this.groupModel.name,
      };
      if (
        this.groupModel.emails != undefined &&
        this.groupModel.emails.length > 0
      ) {
        bodyParams.emails = this.groupModel.emails.join(",");
      }

      return extranetService.addGroup(bodyParams).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao adicionar o grupo",
            life: 3000,
          });
        }
        response.emailList = response.group_emails.map((m) => m.user_email);
        response.emails = response.emailList.join(",");
        this.groupsList.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Grupo Criado",
          detail: "O grupo foi criado com sucesso",
          life: 3000,
        });
        return this.closeGroupForm();
      });
    },
    updateGroup(group) {
      return (this.groupModel = {
        showForm: true,
        id: group.id,
        name: group.name,
        emails: group.emailList,
      });
    },
    saveUpdateGroup() {
      let bodyParams = {
        name: this.groupModel.name,
      };
      if (
        this.groupModel.emails != undefined &&
        this.groupModel.emails.length > 0
      ) {
        bodyParams.emails = this.groupModel.emails.join(",");
      }
      return extranetService
        .updategroup(this.groupModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao atualizar o grupo",
              life: 3000,
            });
          }
          response.emailList = response.group_emails.map((m) => m.user_email);
          response.emails = response.emailList.join(",");

          this.groupsList.splice(this.getIndex(this.groupModel.id), 1);
          this.groupsList.push(response);
          this.$toast.add({
            severity: "success",
            summary: "Grupo Atualizado",
            detail: "O grupo foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeGroupForm();
        });
    },
    getIndex(id) {
      return this.groupsList.findIndex((group) => group.id == id);
    },
  },
};
</script>
<style>
.p-inputtext.p-chips-multiple-container {
  display: block !important;
  width: 100% !important;
}
</style>
